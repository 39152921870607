<template>
  <div id="modalCreateOrEditFiller" class="modal fade" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ title }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            title="Fechar"
            @click="$emit('closeModal', false)"
          />
        </div>
        <form @submit.prevent="saveFiller">
          <div class="modal-body">
            <div class="row g-3">
              <div class="col-sm-12">
                <label class="form-label">
                  Código do Usuário: <span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': v$.filler.CODUSUARIO.$error,
                  }"
                  placeholder="primeironome.ultimonome"
                  v-model="v$.filler.CODUSUARIO.$model"
                />
                <span
                  v-if="v$.filler.CODUSUARIO.required.$invalid"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
            </div>
          </div>
          <div class="modal-footer border-top-0 pt-0">
            <button
              type="button"
              class="btn btn-link"
              data-bs-dismiss="modal"
              title="Cancelar"
              @click="$emit('closeModal', false)"
            >
              Cancelar
            </button>
            <button
              type="submit"
              class="btn btn-primary"
              title="Cadastrar"
              :disabled="v$.$invalid || disableButton"
            >
              <span v-if="disableButton">
                <i class="ph-spinner spinner" />
              </span>
              <span v-else>{{ title }}</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { showModal, hideModal } from '@/utils/modal'

export default {
  name: 'ParametersFillerModal',
  props: {
    stepId: {
      type: Number,
      default: null,
    },
    fillerId: {
      type: Number,
      default: null,
    },
  },
  setup() {
    return { v$: useVuelidate() }
  },

  data() {
    return {
      title: 'Cadastrar Responsável',
      filler: {
        IDUSUARIO: null,
        NUMETAPA: this.stepId,
        CODUSUARIO: '',
      },
      disableButton: false,
    }
  },

  methods: {
    async getFiller(_fillerId) {
      try {
        this.filler = await this.$store.dispatch('getFiller', {
          fillerId: _fillerId,
          stepId: this.stepId,
          companyCode: this.$route.params.code,
        })
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    async saveFiller() {
      if (!this.v$.$invalid) {
        this.disableButton = true
        try {
          let response = null
          if (this.filler.IDUSUARIO) {
            response = await this.$store.dispatch('updateFiller', {
              stepId: this.stepId,
              filler: this.filler,
              companyId: this.$route.params.code,
            })
          } else {
            response = await this.$store.dispatch('createFiller', {
              stepId: this.stepId,
              filler: this.filler,
              companyId: this.$route.params.code,
            })
          }
          hideModal()

          this.$root.$refs.notification.success(response.message)
          this.$emit('closeModal', true)
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$refs.notification.warning(error.message)
          } else {
            this.$root.$refs.notification.error(
              'Não foi possível se conectar ao servidor.',
            )
          }
        }
      }
    },
  },

  validations() {
    return {
      filler: {
        CODUSUARIO: {
          required,
        },
      },
    }
  },

  mounted() {
    if (this.fillerId) {
      this.title = 'Atualizar Responsável'
      this.getFiller(this.fillerId)
    }
    showModal('modalCreateOrEditFiller')
  },
}
</script>
