<template>
  <ParametersStepModal
    v-if="showModalStep"
    :selectedStep="selectedStep.NUMETAPA"
    @closeModal="closeModal"
  />
  <ParametersFillerModal
    v-if="showModalFiller"
    :fillerId="selectedFiller"
    :stepId="step.NUMETAPA"
    @closeModal="closeModal"
  />
  <ParametersConfirmDeleteFillerModal
    v-if="showModalDeleteFiller"
    :filler="selectedFiller"
    @closeModal="closeModal"
  />
  <ParametersFormModal
    v-if="showModalForm"
    :step="step"
    @closeModal="closeModal"
  />
  <ParametersConfirmDeleteFormModal
    v-if="showModalDeleteForm"
    :stepId="selectedStep.NUMETAPA"
    :form="selectedForm"
    @closeModal="closeModal"
  />
  <div class="card card-step">
    <div class="card-body pb-1 border-bottom-1">
      <div class="d-flex">
        <div class="flex-fill">
          <h6 class="mb-0">
            {{ step.NOME }}
            <button
              class="btn btn-link btn-sm text-warning"
              @click="editStepModal(step.ID)"
            >
              <i class="ph-pencil"></i>
            </button>
          </h6>
          <p class="text-muted mb-1">
            Avaliação: <span v-html="answerLabel(step.PREENCHIMENTO)"></span>
          </p>
          <p class="text-muted mb-1">
            Vencimento:
            <span v-html="dateTimeSqlToDateBR(step.VALIDADE)"></span>
          </p>
        </div>

        <div class="ms-3 align-self-center">
          <i
            v-if="step.STATUS == 1"
            class="ph-check-circle ph-2x text-success"
          ></i>
          <i v-if="step.STATUS == 0" class="ph-x-circle ph-2x text-danger"></i>
        </div>
      </div>
    </div>
    <div class="card-body pt-1">
      <div class="d-flex">
        <div class="flex-fill">
          <h6 class="mb-0">Responsáveis</h6>
        </div>
        <div class="ms-3 align-self-center">
          <button
            class="btn btn-link btn-sm"
            title="Adicionar responsável"
            @click="addFiller()"
          >
            <i class="ph-user-gear text-teal"></i>
          </button>
        </div>
      </div>
      <div v-if="responsables.length > 0">
        <div v-for="filler in responsables" :key="filler.IDUSUARIO">
          <div class="d-flex align-items-center mb-1 justify-content-between">
            <div>
              <span>{{ filler.CODUSUARIO }}</span>
            </div>
            <div class="text-end edit-btns">
              <button
                class="btn btn-link btn-sm text-warning"
                title="Editar código do responsável"
                @click="addFiller(filler)"
              >
                <i class="ph-pencil"></i>
              </button>
              <button
                class="btn btn-link btn-sm text-danger"
                title="Remover Responsável"
                @click="deleteFiller(filler)"
              >
                <i class="ph-x"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="d-flex">
        <div class="flex-fill">
          <h6 class="mb-0">Formulários</h6>
        </div>
        <div class="ms-3 align-self-center">
          <button
            class="btn btn-link btn-sm"
            title="Adicionar Formulário"
            @click="addForm()"
          >
            <i class="ph-textbox text-teal"></i>
          </button>
        </div>
      </div>
      <div v-if="forms.length > 0">
        <div v-for="form in forms" :key="form.IDFORMULARIO">
          <div>
            <div class="d-flex align-items-center justify-content-between mb-1">
              <div>
                <span>{{ form.NOME }}</span>
              </div>
              <div class="">
                <button
                  class="btn btn-link btn-sm text-danger"
                  title="Remover Formulário"
                  @click="deleteForm(step, form)"
                >
                  <i class="ph-x"></i>
                </button>
              </div>
            </div>
            <div>
              <a :href="form.LINK">
                <em>{{ form.LINK }}</em></a
              >
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-between mb-1">
            <div>
              <i
                v-if="form.STATUS_FORMULARIO_ETAPA == 0"
                class="bagde bg-primary text-white rounded-pill px-2"
                >Aberto</i
              >
              <i
                v-if="form.STATUS_FORMULARIO_ETAPA == 1"
                class="bagde bg-success text-white rounded-pill px-2"
                >Encerrado</i
              >
            </div>
            <div class="">
              <i class="bagde bg-success text-white rounded-pill px-2"
                >V. {{ form.VERSAO }}</i
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body" v-if="step.INICIO">
      <p class="text-muted mb-1">
        Início:
        <span v-html="dateTimeSqlToDateTimeBR(step.INICIO)"></span>
      </p>
      <p class="text-muted mb-1" v-if="step.TERMINO">
        Término:
        <span v-html="dateTimeSqlToDateTimeBR(step.TERMINO)"></span>
      </p>
    </div>
  </div>
</template>
<style>
.card-step {
  max-width: 400px;
  height: 100%;
  display: flex;
  align-content: space-around;
  width: -webkit-fill-available;
  margin: 1rem;
}
.edit-btns {
  min-width: 60px;
}
</style>
<script>
import ParametersStepModal from '@/components/params/ParametersStepModal.vue'
import ParametersFillerModal from '@/components/params/ParametersFillerModal.vue'
import ParametersConfirmDeleteFillerModal from '@/components/params/ParametersConfirmDeleteFillerModal.vue'
import ParametersFormModal from '@/components/params/ParametersFormModal.vue'
import ParametersConfirmDeleteFormModal from '@/components/params/ParametersConfirmDeleteFormModal.vue'
import { answerLabel } from '@/utils/strings'
import { dateTimeSqlToDateBR, dateTimeSqlToDateTimeBR } from '@/utils/date'

export default {
  name: 'ParametersStepData',
  props: {
    step: {
      type: Object,
      required: true,
    },
  },
  components: {
    ParametersStepModal,
    ParametersFillerModal,
    ParametersConfirmDeleteFillerModal,
    ParametersConfirmDeleteFormModal,
    ParametersFormModal,
  },
  data() {
    return {
      answerLabel,
      dateTimeSqlToDateBR,
      dateTimeSqlToDateTimeBR,
      steps: [],
      levels: [],
      responsables: [],
      forms: [],
      showModalStep: false,
      showModalFiller: false,
      showModalDeleteFiller: false,
      showModalForm: false,
      showModalDeleteForm: false,
      selectedStep: null,
      selectedFiller: null,
      selectedForm: null,
    }
  },

  methods: {
    closeModal(needReload) {
      this.showModalStep = false
      this.showModalFiller = false
      this.showModalDeleteFiller = false
      this.showModalForm = false
      if (needReload) {
        this.getStepFillers()
        this.getStepForms()
      }
    },
    editStepModal() {
      this.selectedStep = this.step
      this.showModalStep = true
    },
    deleteFiller(filler) {
      this.selectedFiller = filler
      this.showModalDeleteFiller = true
    },
    deleteForm(step, form) {
      this.selectedForm = form
      this.selectedStep = this.step
      this.showModalDeleteForm = true
    },
    addFiller(filler) {
      if (filler) {
        this.selectedFiller = filler.IDUSUARIO
      } else {
        this.selectedFiller = null
      }
      this.showModalFiller = true
    },
    addForm() {
      this.showModalForm = true
    },
    async getStepFillers() {
      try {
        this.responsables = await this.$store.dispatch('getStepFillers', {
          stepId: this.step.NUMETAPA,
          companyCode: this.$route.params.code,
        })
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
    async getStepForms() {
      try {
        this.forms = await this.$store.dispatch('getStepForms', {
          stepId: this.step.NUMETAPA,
          companyCode: this.$route.params.code,
        })
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },

  mounted() {
    this.getStepFillers()
    this.getStepForms()
  },
}
</script>
