<template>
  <div class="container-fluid">
    <div class="card">
      <div class="card-header d-flex align-items-center p-1">
        <h4 class="mb-0 ms-2 text-secondary">Selecione</h4>
      </div>
      <div class="card-body p-0">
        <div v-if="isLoading"><LoadingData /></div>
        <div v-else class="company-list">
          <div v-for="company in companies" :key="company.CODUNIDADE">
            <router-link
              :to="`./parameters/companies/${company.CODUNIDADE}/steps`"
            >
              <h5>{{ company.NOMEFANTASIA }}</h5>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.company-list {
  padding: 0 1rem;
}
.company-list > div {
  margin-top: 2rem;
  margin-left: 1rem;
}
</style>
<script>
import LoadingData from '@/components/commons/globals/LoadingData.vue'

export default {
  name: 'ParametersHome',
  data() {
    return {
      isLoading: true,
      companies: [],
    }
  },
  components: {
    LoadingData,
  },
  methods: {
    async getCompanies() {
      try {
        this.companies = await this.$store.dispatch('getCompanies')
        this.isLoading = false
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch('setPageTitle', `Parâmetros - Unidades`)
    this.getCompanies()
  },
}
</script>
