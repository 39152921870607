<template>
  <div id="modalDeleteForm" class="modal fade" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Excluir Formulário</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            title="Fechar"
            @click="$emit('closeModal', false)"
          />
        </div>
        <form @submit.prevent="deleteForm">
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <h6>Confirma a exclusão do formulário {{ form.NOME }}?</h6>
              </div>
            </div>
          </div>
          <div class="modal-footer border-top-0 pt-0">
            <button
              type="button"
              class="btn btn-link text-danger"
              data-bs-dismiss="modal"
              title="Cancelar"
              @click="$emit('closeModal', false)"
            >
              Cancelar
            </button>
            <button
              type="submit"
              class="btn btn-danger"
              title="Cadastrar"
              :disabled="disableButton"
            >
              <span v-if="disableButton">
                <i class="ph-spinner spinner" />
              </span>
              <span v-else>Excluir</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { showModal, hideModal } from '@/utils/modal'

export default {
  name: 'ParametersConfirmDeleteFillerModal',
  props: {
    form: {
      type: Object,
      required: true,
    },
    stepId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      disableButton: false,
    }
  },

  methods: {
    async deleteForm() {
      this.disableButton = true
      try {
        let response = null

        response = await this.$store.dispatch('deleteForm', {
          stepId: this.stepId,
          formId: this.form.IDFORMETAPA,
          companyId: this.$route.params.code,
        })

        hideModal()

        this.$root.$refs.notification.success(response.message)
        this.$emit('closeModal', true)
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },

  mounted() {
    showModal('modalDeleteForm')
  },
}
</script>
