<template>
  <div class="container-fluid">
    <ParametersStepModal v-if="showModalStep" @closeModal="closeModal" />
    <div class="card">
      <div v-if="isLoadingCompany"><LoadingData /></div>

      <div v-else class="card-header d-flex align-items-center p-1">
        <h4 class="mb-0 ms-2 text-secondary">
          Fluxo de Preenchimento - {{ company.NOMEFANTASIA }}
        </h4>
        <div class="ms-auto">
          <button
            type="button"
            class="btn btn-outline-info"
            @click="showModalStep = true"
          >
            <i class="ph-download me-2"></i> Cadastrar Novo Fluxo
          </button>
        </div>
      </div>
      <div class="card-body p-0">
        <div v-if="isLoadingCompanySteps"><LoadingData /></div>
        <div v-else class="">
          <div v-if="levels.length > 0">
            <div v-for="level in levels" :key="level">
              <div class="card bg-teal card-body">
                <h4 class="text-white">
                  <span class="me-2">Nível</span>
                  <a
                    href="#"
                    class="d-inline-flex align-items-center justify-content-center bg-warning text-white lh-1 rounded-pill w-40px h-40px me-3"
                    ><span class="letter-icon">{{ level }}</span></a
                  >
                </h4>
                <div
                  class="d-flex justify-content-center align-items-stretch align-content-center"
                >
                  <template v-for="step in steps" :key="step.NUMETAPA">
                    <ParametersStepData
                      v-if="step.NIVEL == level"
                      :step="step"
                    />
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div v-if="steps.length > 0" class="main-flow"></div>
          <div v-else>
            <div class="text-center my-5">
              <h3>Fluxo não cadastrado para esta unidade.</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.company-list {
  padding: 0 1rem;
}
.company-list > div {
  margin-top: 2rem;
  margin-left: 1rem;
}
</style>
<script>
import LoadingData from '@/components/commons/globals/LoadingData.vue'
import ParametersStepModal from '@/components/params/ParametersStepModal.vue'
import ParametersStepData from '@/components/params/ParametersStepData.vue'

export default {
  name: 'ParametersCompanySteps',
  data() {
    return {
      isLoadingCompany: true,
      isLoadingCompanySteps: true,
      companyId: null,
      company: {},
      steps: [],
      levels: [],
      showModalStep: false,
    }
  },
  components: {
    LoadingData,
    ParametersStepModal,
    ParametersStepData,
  },
  methods: {
    closeModal(needReload) {
      this.showModalStep = false
      if (needReload) {
        this.getSteps()
      }
    },
    async getCompany() {
      try {
        this.company = await this.$store.dispatch('getCompany', this.companyId)
        this.$store.dispatch(
          'setPageTitle',
          `Parâmetros - Fluxo - ${this.company.NOMEFANTASIA}`,
        )
        this.getSteps()
        this.isLoadingCompany = false
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
    async getSteps() {
      try {
        this.isLoadingCompanySteps = true
        this.steps = await this.$store.dispatch('getSteps', this.companyId)
        this.levels = this.steps
          .map((step) => step.NIVEL)
          .reduce((acc, cur) => {
            if (!acc.includes(cur)) {
              acc.push(cur)
            }
            return acc
          }, [])
        this.isLoadingCompanySteps = false
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },
  mounted() {
    this.companyId = this.$route.params.code
    this.getCompany()
  },
}
</script>
