<template>
  <div id="modalCreateOrEditStep" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ title }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            title="Fechar"
            @click="$emit('closeModal', false)"
          />
        </div>
        <form @submit.prevent="saveStep">
          <div class="modal-body">
            <div class="row g-3">
              <div class="col-sm-12">
                <label class="form-label">
                  Nome da Etapa: <span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': v$.step.NOME.$error,
                  }"
                  placeholder="Nome da Etapa"
                  v-model="v$.step.NOME.$model"
                />
                <span
                  v-if="v$.step.NOME.required.$invalid"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <label class="form-label">
                  Preencher Até: <span class="text-danger">*</span>
                </label>
                <input
                  class="form-control"
                  type="date"
                  :class="{
                    'is-invalid': v$.step.VALIDADE.$error,
                  }"
                  v-model="v$.step.VALIDADE.$model"
                />
                <span
                  v-if="v$.step.VALIDADE.required.$invalid"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <label class="form-label">
                  Status: <span class="text-danger">*</span>
                </label>
                <select
                  class="form-select"
                  :class="{
                    'is-invalid': v$.step.STATUS.$error,
                  }"
                  v-model="v$.step.STATUS.$model"
                >
                  <option value="" disabled>Selecione uma opção</option>
                  <option
                    :value="status.value"
                    v-for="status in status"
                    :key="status.value"
                  >
                    {{ status.label }}
                  </option>
                </select>
                <span
                  v-if="v$.step.STATUS.required.$invalid"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <label class="form-label">
                  Nível: <span class="text-danger">*</span>
                </label>
                <select
                  class="form-select"
                  :class="{
                    'is-invalid': v$.step.NIVEL.$error,
                  }"
                  v-model="v$.step.NIVEL.$model"
                >
                  <option value="" disabled>Selecione uma opção</option>

                  <option :value="level" v-for="level in levels" :key="level">
                    {{ level }}
                  </option>
                </select>
                <span
                  v-if="v$.step.NIVEL.required.$invalid"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div class="col-sm-12">
                <label class="form-label">
                  Estado de Preenchimento: <span class="text-danger">*</span>
                </label>
                <select class="form-select" v-model="step.PREENCHIMENTO">
                  <option value="" disabled>Selecione uma opção</option>

                  <option
                    :value="status.value"
                    v-for="status in statusConfig"
                    :key="status.value"
                  >
                    {{ status.label }}
                  </option>
                </select>
                <span
                  v-if="v$.step.NIVEL.required.$invalid"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
            </div>
          </div>
          <div class="modal-footer border-top-0 pt-0">
            <button
              type="button"
              class="btn btn-link"
              data-bs-dismiss="modal"
              title="Cancelar"
              @click="$emit('closeModal', false)"
            >
              Cancelar
            </button>
            <button
              type="submit"
              class="btn btn-primary"
              title="Cadastrar"
              :disabled="v$.$invalid || disableButton"
            >
              <span v-if="disableButton">
                <i class="ph-spinner spinner" />
              </span>
              <span v-else>{{ title }}</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { showModal, hideModal } from '@/utils/modal'

export default {
  name: 'ParametersStepModal',
  props: {
    selectedStep: {
      type: Number,
      default: null,
    },
  },
  setup() {
    return { v$: useVuelidate() }
  },

  data() {
    return {
      steps: [],
      levels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      showManagerConfig: false,
      status: [
        { value: 1, label: 'Ativo' },
        { value: 0, label: 'Inativo' },
      ],
      statusConfig: [
        { value: 'N', label: 'Não Iniciado' },
        { value: 'A', label: 'Em Aberto (Preenchimento)' },
        { value: 'C', label: 'Concluído' },
        { value: 'R', label: 'Reprovado' },
      ],
      step: {
        NUMETAPA: null,
        CODUNIDADE: this.$route.params.code,
        NOME: '',
        STATUS: '',
        NIVEL: null,
        VALIDADE: '',
      },
      title: 'Cadastrar Etapa',
      disableButton: false,
    }
  },

  methods: {
    async getSteps() {
      try {
        this.steps = await this.$store.dispatch(
          'getSteps',
          this.$route.params.code,
        )
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    async getStep(_stepId) {
      try {
        this.step = await this.$store.dispatch('getStep', {
          stepId: _stepId,
          companyCode: this.$route.params.code,
        })
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    async saveStep() {
      if (!this.v$.$invalid) {
        this.disableButton = true
        try {
          let response = null
          if (this.step.NUMETAPA) {
            response = await this.$store.dispatch('updateStep', {
              stepId: this.step.NUMETAPA,
              step: this.step,
              companyId: this.$route.params.code,
            })
          } else {
            response = await this.$store.dispatch('createStep', {
              step: this.step,
              companyId: this.$route.params.code,
            })
          }
          hideModal()

          this.$root.$refs.notification.success(response.message)
          this.$emit('closeModal', true)
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$refs.notification.warning(error.message)
          } else {
            this.$root.$refs.notification.error(
              'Não foi possível se conectar ao servidor.',
            )
          }
        }
      }
    },
  },

  validations() {
    return {
      step: {
        NOME: {
          required,
        },
        STATUS: {
          required,
        },
        NIVEL: {
          required,
        },
        VALIDADE: {
          required,
        },
      },
    }
  },

  mounted() {
    if (this.selectedStep) {
      this.title = 'Atualizar Etapa'
      this.getStep(this.selectedStep)
      this.showManagerConfig = true
    }
    this.getSteps()
    showModal('modalCreateOrEditStep')
  },
}
</script>
