const dateTimeSqlToDateTimeBR = (value) => {
  const date = new Date(value)
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  }
  return date.toLocaleDateString('pt-BR', options)
}

const dateTimeSqlToDateBR = (value) => {
  const date = new Date(`${value} 00:00:00`)
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }
  return date.toLocaleDateString('pt-BR', options)
}

const dateBrToIso = (value) => {
  const date = value.split('/')
  const dateIso = `${date[2]}-${date[1]}-${date[0]}`
  return dateIso
}

const dateIncreaseDay = (day) => {
  const date = new Date()
  date.setDate(date.getDate() + day)

  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }
  return date.toLocaleDateString('pt-BR', options)
}

const tomorrow = () => {
  const today = new Date()
  const tomorrowDate = new Date(today.setDate(today.getDate() + 1))
  return [
    tomorrowDate.getFullYear(),
    tomorrowDate.getMonth() + 1 < 10
      ? `0${tomorrowDate.getMonth() + 1}`
      : tomorrowDate.getMonth() + 1,
    tomorrowDate.getDate(),
  ].join('-')
}

const getFirstDayOfMonth = (year, month) => {
  const date = new Date(`${year}-${month}-01 00:00:00`)
  return date.toISOString().split('T')[0]
}

const getLastDayOfMonth = (year, month) => {
  const lastDay = new Date(year, month, 0).getDate()
  const date = new Date(`${year}-${month}-${lastDay} 00:00:00`)
  return date.toISOString().split('T')[0]
}

export {
  dateTimeSqlToDateTimeBR,
  dateTimeSqlToDateBR,
  dateBrToIso,
  dateIncreaseDay,
  tomorrow,
  getFirstDayOfMonth,
  getLastDayOfMonth,
}
