<template>
  <div id="modalCreateOrEditForm" class="modal fade" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Vincular Formulário</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            title="Fechar"
            @click="$emit('closeModal', false)"
          />
        </div>
        <form @submit.prevent="saveForm">
          <div class="modal-body">
            <div class="row g-3">
              <div class="col-sm-12">
                <label class="form-label">
                  Selecione um formulário: <span class="text-danger">*</span>
                </label>
                <select
                  class="form-select"
                  :class="{
                    'is-invalid': v$.form.IDFORMULARIO.$error,
                  }"
                  v-model="v$.form.IDFORMULARIO.$model"
                >
                  <option value="" disabled>Selecione uma opção</option>
                  <option
                    :value="form.IDFORMULARIO"
                    v-for="form in forms"
                    :key="form.IDFORMULARIO"
                  >
                    {{ form.NOME }}
                  </option>
                </select>
                <span
                  v-if="v$.form.IDFORMULARIO.required.$invalid"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div class="col-12">
                <label class="form-label" for="formLink"
                  >Planilha de Modelo:</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="formLink"
                  v-model="form.LINK"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer border-top-0 pt-0">
            <button
              type="text"
              class="btn btn-link"
              data-bs-dismiss="modal"
              maxLength="500"
              title="Cancelar"
              @click="$emit('closeModal', false)"
            >
              Cancelar
            </button>
            <button
              type="submit"
              class="btn btn-primary"
              title="Cadastrar"
              :disabled="v$.$invalid || disableButton"
            >
              <span v-if="disableButton">
                <i class="ph-spinner spinner" />
              </span>
              <span v-else>Vincular</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { showModal, hideModal } from '@/utils/modal'

export default {
  name: 'ParametersFormModal',
  props: {
    step: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return { v$: useVuelidate() }
  },

  data() {
    return {
      form: {
        IDFORMULARIO: null,
        NUMETAPA: this.step.NUMETAPA,
      },
      forms: [],
      disableButton: false,
    }
  },

  methods: {
    async getForms() {
      try {
        this.forms = await this.$store.dispatch('getAllForms')
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
    async saveForm() {
      if (!this.v$.$invalid) {
        this.disableButton = true
        try {
          const response = await this.$store.dispatch('addFormToStep', {
            stepId: this.step.NUMETAPA,
            companyId: this.$route.params.code,
            formId: this.form.formId,
            form: this.form,
          })
          hideModal()
          this.$root.$refs.notification.success(response.message)
          this.$emit('closeModal', true)
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$refs.notification.warning(error.message)
          } else {
            this.$root.$refs.notification.error(
              'Não foi possível se conectar ao servidor.',
            )
          }
        }
      }
    },
  },

  validations() {
    return {
      form: {
        IDFORMULARIO: {
          required,
        },
      },
    }
  },

  mounted() {
    this.getForms()
    showModal('modalCreateOrEditForm')
  },
}
</script>
